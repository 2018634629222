import React from "react"
import Modal from "@material-ui/core/Modal"
import YouTube from "react-youtube"
import Image from "../Image"
import PropTypes from "prop-types"
import PlayButton from "../../../assets/images/play-button-arrow.svg"
import "./index.css"

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const Index = props => {
  // we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle)
  const [open, setOpen] = React.useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const body = (
    <div style={modalStyle} className={"modal-video-paper"}>
      <YouTube
        videoId={props.videoId}
        opts={{
          playerVars: {
            autoplay: 1,
            rel: 0,
            showinfo: 0,
            modestbranding: 1,
            enablejsapi: 1,
          },
        }}
        containerClassName={"video-container"}
      />
    </div>
  )

  return (
    <div>
      <div>
        <Modal open={open} onClose={handleClose}>
          {body}
        </Modal>
      </div>
      <Image
        src={PlayButton}
        alt="play button"
        className={"play-button-arrow"}
        style={{
          height: 64,
          width: 64,
          position: "absolute",
          margin: "auto",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          textAlign: "center",
          opacity: "40%",
        }}
        onClick={handleOpen}
      />
      <Image
        src={
          props.thumbnailSrc !== undefined
            ? props.thumbnailSrc
            : `https://i.ytimg.com/vi_webp/${props.videoId}/hqdefault.webp`
        }
        alt={`${props.videoId}-thumbnail`}
        width={528}
        height={396}
        onClick={handleOpen}
      />
    </div>
  )
}

Index.defaultProps = {
  videoId: "",
  thumbnailSrc: undefined,
}

Index.propTypes = {
  videoId: PropTypes.string,
  thumbnailSrc: PropTypes.string,
}

export default Index
